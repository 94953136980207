import { createRouter, createWebHashHistory } from 'vue-router'
import routes from "./routes.js";
import store from "../store/index.js";

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

router.beforeEach((to, from, next) => {
  if (to.path == "/") {
    if (store.state.isAuthenticated == true) {
      next("/dashboard");
    }
    else {
      next();
    }
  }
  else {
    if (store.state.isAuthenticated == false) {
      next("/");
    }
    else {
      next();
    }

  }
});

export default router
