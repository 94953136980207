<template>
  <top-bar v-if="isAuthenticated"></top-bar>
  <side-bar v-if="isAuthenticated"></side-bar>
  <div class="main-div">
    <router-view />
  </div>
  <foot-bar v-if="isAuthenticated"></foot-bar>
</template>

<script>
import TopBar from "@/components/TopBar.vue";
import FootBar from "@/components/FootBar.vue";
import SideBar from "@/components/SideBar.vue";

export default {
  components: {
    TopBar,
    FootBar,
    SideBar,
  },
  methods: {
    checkAuth() {
      if (localStorage.getItem("Auth")) {
        this.$store.state.isAuthenticated = true;
        this.$store.state.userId = localStorage.getItem("Uid");
        this.$store.state.userName = localStorage.getItem("Uname");
      } else {
        this.$store.state.isAuthenticated = false;
      }
    },
  },
  computed: {
    isAuthenticated() {
      return this.$store.state.isAuthenticated;
    },
  },
  mounted() {
    this.checkAuth();
  },
};
</script>

<style>
html {
  margin: 0px;
  padding: 0px;
  clear: both;
}
body {
  margin: 0px;
  padding: 0px;
  clear: both;
  background-color: rgb(240, 238, 238);
}

:root {
  --el-color-primary: rgb(17, 75, 235) !important;
  --el-color-success: rgb(23, 201, 100) !important;
  --el-color-danger: rgb(242, 19, 93) !important;
  --el-color-warn: rgb(254, 130, 0) !important;
  --el-color-dark: rgb(36, 33, 69) !important;
}

.el-button {
  border-radius: 20px !important;
  font-size: 15px !important;
  font-weight: bold !important;
}
#app {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}
.main-div {
  position: relative;
  display: block;
  top: 0px;
  height: calc(100vh - 60px);
  background-size: cover;
}
</style>
<style>
@import "./css/formcontrol.css";
</style>
